// App Context types for orderliness
export const CURRENT_USER_EMAIL = 'CURRENT_USER_EMAIL';
export const ACCOUNT_NAME = 'ACCOUNT_NAME';
export const ACCOUNT_VERIFIED = 'ACCOUNT_VERIFIED';
export const USER_NAME = 'USER_NAME';
export const SELECTED_BUSINESS = 'SELECTED_BUSINESS';
export const ALL_BUSINESSES = 'ALL_BUSINESSES';
export const ALL_CUSTOMERS = 'ALL_CUSTOMERS';
export const TOTAL_SETTLEMENTS = 'TOTAL_SETTLEMENTS';
export const USER_DATA = 'USER_DATA';
export const ONBOARDING_SHAREHOLDERS = 'ONBOARDING_SHAREHOLDERS';
export const SHAREHOLDERS_ERRORS = 'SHAREHOLDERS_ERRORS';
