import React from 'react';

import '../../assets/style/loading.scss';

const Loading = ({ message }) => (
  <div className="app-loader-overlay">
    <center>
      <div className="spinner-container">
        <img src="/circle.svg" className="circle" alt="circle" />
        <img src="/alatpay-logo.svg" className="logo" alt="logo" />
      </div>
      <p className="text text-base">{message || 'Loading...'}</p>
    </center>
  </div>
);

export default Loading;
