import React from "react";
import { Link } from "react-router-dom";
import Button from "../inputs/Button";
import { ReactComponent as Bulb } from "../../assets/svg/Bulb.svg";
import { ReactComponent as Plug } from "../../assets/svg/Plug.svg";
import { ReactComponent as Ellipse } from "../../assets/svg/Ellipse.svg";

function ErrorPage() {
  const reload = () => {
    window.location.reload();
  };

  return (
    <div className="relative overflow-hidden">
      <Plug className="hidden lg:block absolute left-0 top-0" />
      <Ellipse className="hidden lg:block absolute lg:-left-32 xl:left-0 lg:-bottom-40 xl:-bottom-60" />
      <Bulb className="hidden lg:block absolute lg:-right-24 xl:right-0 lg:-bottom-20 xl:-bottom-24" />
      <center className="h-screen flex flex-col items-center text-center justify-center space-y-8">
        <h3 className="text-chinese-black text-center font-bold text-7xl">
          Error
        </h3>
        <h4 className="text-chineseBlack text-center text-2xl max-w-2xl mx-2">
          Oops! Something unexpected happened. You may try refreshing the page
          or try again later
        </h4>
        <div className="flex flex-col sm:flex-row items-center justify-center space-y-2 sm:space-x-4 sm:space-y-0 mt-10">
          <Link to="/">
            <Button label="Back to homepage" className="w-215 h-55" />
          </Link>
          <Button
            label="Refresh Page"
            color="light"
            onClick={reload}
            className="w-215 h-55 bg-alat-light-hover text-alat-light border-1 border-alat-light"
          />
        </div>
      </center>
    </div>
  );
}

export default ErrorPage;
