/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import ErrorPage from '../pages/ErrorPage';

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   hasError: false
    // };
    this.state = {
      error: null
    };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    console.log({ error, errorInfo });
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }
  //   static getDerivedStateFromError(error) {
  //     // Update state so the next render will show the fallback UI.
  //     return { hasError: true };
  //   }

  //   componentDidCatch(error, errorInfo) {
  //     // You can also log the error to an error reporting service
  //     // logErrorToMyService(error, errorInfo);
  //     console.log({ error, errorInfo });
  //   }

  render() {
    if (this.state.error) {
      // You can render any custom fallback UI
      return <ErrorPage />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
