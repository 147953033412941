import clsx from "clsx";
import React from "react";

const Button = ({
  children,
  label,
  type = "button",
  className,
  onClick = () => {},
  disabled = false,
  color = "dark",
  loading = false,
  ...rest
}) => (
  <button
    className={clsx(
      `font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline`,
      color === "light" && "text-alat-dark bg-white border-alat-dark",
      color === "dark" && "bg-alat-light text-white",
      disabled && "opacity-80 cursor-not-allowed",
      className
    )}
    type={type}
    onClick={onClick}
    disabled={disabled || loading}
    {...rest}
  >
    {/* Loading spinner */}
    {loading ? (
      <div className="w-4 mx-auto">
        <svg
          className="w-4 h-4 animate-spin"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 4V2M12 22V20M4.93 4.93L7.76 7.76M16.24 16.24L19.07 19.07M2 12H4M20 12H22M4.93 19.07L7.76 16.24M16.24 7.76L19.07 4.93"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    ) : (
      label || children || "Button"
    )}
  </button>
);

export default Button;
