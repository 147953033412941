export const defaultShareHolder = {
  id: 1,
  firstname: "",
  lastname: "",
  email: "",
  phonenumber: "",
  sharesowned: "",
  shareholderbvn: "",
  idType: 0,
  idFile: null,
  isBvnValidated: false
};

export const idTypes = [
  {
    label: "Driver's License",
    value: 1
  },
  {
    label: "International Passport",
    value: 2
  },
  {
    label: "National ID Card / NIMC Slip",
    value: 3
  },
  {
    label: "Voter's Card",
    value: 4
  },
  {
    label: "Notarized Foreign Passport",
    value: 5
  }
];

export const paymentTypes = {
  All:"*",
  CARD:1,
  BANKTRANSFER:2,
  BANKDETAILS:3,
  NQR:4
}

export const ONBOARDINGSTAGE = {
  BUSINESS_DETAILS: 2,
  BUSINESS_ADDRESS: 3,
  BUSINESS_DOCUMENTATION: 4,
  BUSINESS_SHAREHOLDERS: 5,
  BUSINESS_PAYMENT: 6,
  BUSINESS_CATEGORY: 7, // Business_Category is the stage the user select if they have a registered or unregistered business, also take note that this step comes before step 2. 
  UNREGISTERED_BUSINESS_DETAILS: 8,
  UNREGISTERED_BUSINESS_ADDRESS: 9,
  UNREGISTERED_BUSINESS_PAYMENT: 10,
  UNREGISTERED_BUSINESS_PREVIEW: 11
}
