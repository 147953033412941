import React, { createContext, useContext } from 'react';
import { AppProvider } from './app/AppProvider';

export const CombineContext = createContext();

export default function CombineContextProvider({ children }) {
  return (
    <AppProvider>{children}</AppProvider>
  );
}

export function useCombineContext() {
  return useContext(CombineContext);
}
